import React from 'react'
import Wrapper from 'src/components/shared/Wrapper'
import Title from 'src/components/shared/Title'
import MeritCard from 'src/components/shared/Card/MeritCard'
import { MeritCardWrapper } from 'src/components/shared/Wrapper/CardWrapper'

const Merit: React.FCX = () => {
  const meritList = [
    {
      img: '4.svg',
      title: '新卒・中途入社時に',
      sentence:
        '<strong>新しい勤務地に合わせて</strong>、自由なタイミングで引越しをすることができるよう、社員様のビジネスライフを応援します。'
    },
    {
      img: '5.svg',
      title: 'ご結婚・ご出産に向けて',
      sentence:
        '貴社社員の<strong>ライフステージの移行</strong>に合わせて、より生活に適したお部屋への円滑な引越しができるようになります。'
    },
    {
      img: '6.svg',
      title: 'オフィス転居・増設に伴い',
      sentence:
        '<strong>オフィスの転居や増設</strong>に伴い、勤務エリアが変わる社員様の生活を支援します。'
    }
  ]

  return (
    <Wrapper>
      <Title
        type={['color_main', 'jp_main']}
        text="スムーズのご利用シーン"
        sub="WHENTOUSE"
      />
      <MeritCardWrapper>
        {meritList.map((li) => (
          <MeritCard
            key={li.title}
            title={li.title}
            sentence={li.sentence}
            img={li.img}
          />
        ))}
      </MeritCardWrapper>
    </Wrapper>
  )
}

export default Merit
