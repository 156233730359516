/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import Wrapper, { NarrowWrapper } from 'src/components/shared/Wrapper'
import Form from 'src/components/shared/Form'
import Title from 'src/components/shared/Title'
import { InputTitle } from 'src/components/shared/Label'
import { Input } from 'src/components/shared/Input'
import { CenteredTitle } from 'src/components/shared/Title/CenteredTitle'
import Radio from 'src/components/shared/Radio'
import { Textarea } from 'src/components/shared/Textarea'
import { MainButton } from 'src/components/shared/Button'
import { useBizForm } from './useBizForm'

const BizForm: React.FC = () => {
  const { handleChange, handleSubmit, isValid } = useBizForm()
  const requirementOptions = [
    {
      name: 'サービス説明を聞きたい',
      value: 'サービス説明を聞きたい'
    },
    {
      name: '資料請求',
      value: '資料請求'
    },
    {
      name: 'お申込み',
      value: 'お申込み'
    },
    {
      name: 'その他',
      value: 'その他'
    }
  ]

  return (
    <Wrapper>
      <Title
        type={['color_main', 'jp_main']}
        text="資料請求・お問い合わせ"
        sub="CONTACT"
      />
      <NarrowWrapper>
        <Form onChange={handleChange}>
          <CenteredTitle>
            最短3営業日で送客を開始！ <br />
            導入・維持費用無しで導入可能
          </CenteredTitle>
          <div className="form-section">
            <InputTitle htmlFor="name" required>
              お名前
            </InputTitle>
            <Input
              name="name"
              id="name"
              type="text"
              placeholder="スムーズ 太郎"
            />
          </div>
          <div className="form-section">
            <InputTitle htmlFor="email" required>
              メールアドレス
            </InputTitle>
            <Input
              name="email"
              id="email"
              type="email"
              placeholder="info@smooth.jp"
            />
          </div>
          <div className="form-section">
            <InputTitle htmlFor="company" required>
              会社名・所属
            </InputTitle>
            <Input
              name="company"
              id="company"
              type="text"
              placeholder="株式会社スムーズ"
            />
          </div>
          <div className="form-section">
            <InputTitle htmlFor="company" required>
              お問い合わせ内容
            </InputTitle>
            <Radio name="requirement" options={requirementOptions} />
          </div>
          <div className="form-section">
            <InputTitle htmlFor="company">備考</InputTitle>
            <Textarea name="remarks" rows={5} />
          </div>
          <MainButton
            onClick={handleSubmit}
            type={['normal']}
            disabled={!isValid}
          >
            送信する
          </MainButton>
        </Form>
      </NarrowWrapper>
    </Wrapper>
  )
}

export default BizForm
