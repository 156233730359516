import React from 'react'
import Wrapper from 'src/components/shared/Wrapper'
import ProblemsCard from 'src/components/shared/Card/ProblemsCard'
import ProblemsTitle from 'src/components/shared/Title/ProblemsTitle'
import ProblemsCardWrapper from 'src/components/shared/Wrapper/ProblemsCardWrapper'

const Problems: React.FCX = () => {
  const problemsData = [
    '住宅関連の福利厚生を\n簡単に拡充したい',
    '職場の近くへの居住を\n促進したい',
    '新卒・中途入社のメンバーの\n住まいについてサポートしたい'
  ]

  return (
    <Wrapper>
      <ProblemsTitle>スムーズはこんなお困りごとを解決します</ProblemsTitle>
      <ProblemsCardWrapper>
        {problemsData.map((d) => (
          <ProblemsCard key={d}>{d}</ProblemsCard>
        ))}
      </ProblemsCardWrapper>
    </Wrapper>
  )
}

export default Problems
