import React from 'react'
import { Head } from 'src/components/layout/head'
import { DefaultLayout } from 'src/components/layout/default'
import Biz from 'src/components/pages/Biz'
import { bizPageHeaderItems } from 'src/lib/data/headerItems'

const BizPage: React.FCX = () => {
  return (
    <div className="biz-page">
      <Head title="法人様向け | 株式会社スムーズ" />
      <DefaultLayout headerItems={bizPageHeaderItems} smoothScroll>
        <Biz />
      </DefaultLayout>
    </div>
  )
}

export default BizPage
