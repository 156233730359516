import React from 'react'
import { PageSection } from 'src/components/shared/Section/PageSection'
import Topcatch from './Topcatch'
import Problems from './Problems'
import Logic from './Logic'
import Merit from './Merit'
import Flow from './Flow'
import BizForm from './Form'

const Biz: React.FCX = () => {
  return (
    <div className="biz">
      <Topcatch />
      <PageSection type={['for_bg-blue']}>
        <Problems />
      </PageSection>
      <PageSection id="logic">
        <Logic />
      </PageSection>
      <PageSection type={['for_bg-gray']} id="merit">
        <Merit />
      </PageSection>
      <PageSection id="flow">
        <Flow />
      </PageSection>
      <PageSection type={['for_bg-gray']} id="contact">
        <BizForm />
      </PageSection>
    </div>
  )
}

export default Biz
