import React from 'react'
import Wrapper from 'src/components/shared/Wrapper'
import Title from 'src/components/shared/Title'
import LogicCard from 'src/components/shared/Card/LogicCard'

const Logic: React.FC = () => {
  return (
    <Wrapper>
      <Title
        type={['color_main', 'jp_main']}
        text="スムーズの仕組み"
        sub="ABOUT SMOOTH"
      />
      <LogicCard
        text={`引越初期費用の分割サービス。\nスムーズが敷金・礼金・初月家賃などの初期費用を一時的に立替払いすることで、\n引越希望者の引越しを後押しします。`}
      />
    </Wrapper>
  )
}

export default Logic
