import React from 'react'
import styled from 'styled-components'
import Img from 'src/components/shared/Img'
import { topcatchStyles } from 'src/components/shared/Topcatch/styles'
import { mq, ma, font } from 'src/lib/styles/variable'
import { MainButton } from 'src/components/shared/Button'

const Topcatch: React.FCX = ({ className }) => {
  return (
    <div className={`${className} ${className}--for_lp`}>
      <Img filePath="topcatch/biz.jpg" />
      <div className={`${className}-text ${className}-text--text_smaller`}>
        <span className={`${className}-text__free`}>
          <Img filePath="topcatch/free.png" />
        </span>
        <h1
          className={`
            ${className}-text__heading
            ${className}-text__heading--text_smaller
            u-font-midashigo
          `}
        >
          従業員様の引越しを
          <br />
          初期費用分割払いで応援する
          <br />
          福利厚生サービスです
          <MainButton
            type={['normal', 'color_yellow']}
            element="A"
            href="#contact"
          >
            資料を請求する
          </MainButton>
        </h1>
      </div>
    </div>
  )
}

export default styled(Topcatch)`
  ${topcatchStyles}

  &-text {
    &__free {
      width: 150px;
      position: absolute;
      top: -130px;
      left: -45px;
      ${mq('tab')} {
        width: 100px;
        top: -80px;
        left: -10px;
      }
    }
  }

  &-text--text_smaller {
    width: 90%;
    max-width: 1000px;
    left: 50%;
    transform: translate(-50%, -50%);
    ${mq('sp_wide')} {
      margin-top: 50px;
    }
    > h1 > a {
      ${ma}
      ${font(21)};
      margin-top: 45px;
      width: 300px;
      height: 69px;
      line-height: 69px;
      font-weight: 300;

      ${mq('medium')} {
        ${font(16)};
        margin-top: 30px;
        margin-right: auto;
        margin-left: auto;
        width: 280px;
        line-height: 60px;
        height: 60px;
      }

      ${mq('tab')} {
        ${font(14)};
        width: 220px;
        height: 50px;
        line-height: 50px;
        margin-bottom: 30px;
      }
      ${mq('sp')} {
        width: 100%;
      }
    }
  }
`
